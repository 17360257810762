import { CommonModule } from "@angular/common";
import {
  Component,
  ViewChild,
  AfterViewInit,
  OnInit,
  Input,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatSortModule, MatSort } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import {
  EntitlementService,
  Entitlement,
} from "@kelyn-technologies/dsio-angular-lib/dsio-api";

@Component({
  selector: "app-tenant-entitlements",
  standalone: true,
  templateUrl: "./tenant-entitlements.component.html",
  imports: [MatButtonModule, MatTableModule, MatSortModule, CommonModule],
  styleUrls: ["./tenant-entitlements.component.css"],
})
export class TenantEntitlementsComponent implements AfterViewInit, OnInit {
  @Input() public tenantId = "";

  displayedColumns: string[] = [
    "Entitlement Name",
    "Type",
    "Start Date",
    "End Date",
  ];
  dataSource = new MatTableDataSource<Entitlement>([]);
  public selectedRow: string = "1";
  entitlements: Entitlement[] = [];

  constructor(private entitlementService: EntitlementService) {}

  @ViewChild(MatSort) sort!: MatSort;

  ngOnInit() {
    this.handleGetTenantEntitlements();
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  setSelected(row: Entitlement): void {
    this.selectedRow = row.product_source + "#" + row.product_code;
  }

  addEntitlement(): void {
    // console.log(` Add New Sku.`);
  }

  removeEntitlement(_skuId: string): void {
    // console.log(` Remove Sku: ${skuId}`);
  }

  async handleGetTenantEntitlements() {
    this.entitlementService
      .getEntitlementsByTenant(this.tenantId)
      .subscribe((result) => {
        this.entitlements = result.entitlements;
        this.dataSource.data = this.entitlements;
      });
  }
}
