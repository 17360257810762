import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import { KeycloakService, } from 'keycloak-angular';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSortModule, MatSort} from '@angular/material/sort';
import {CommonModule} from '@angular/common';
import {MatProgressBarModule} from '@angular/material/progress-bar'



// export interface Tenant{
//   tenantId: String,
//   companyName: String,
//   primaryContact: String,
//   status: String,

// }

//TODO: Change the type of tenant data from the API response to match the UI
// Need: Company Name, Primary Contact, and Status

export interface TenantEntitlement{
  entitlement1: boolean
}

export interface Tenant{
    tenantId: String,
    companyName: String,
    primaryContact: String,
    status: String,
    aggrementType?: String,
    realm?: String,
    entitlements?: {
    Entitlements: TenantEntitlement
  
  }

}

@Component({
  selector: 'app-tenants',
  standalone: true,
  templateUrl: './tenants.component.html',
  imports: [MatButtonModule, MatTableModule, MatSortModule, CommonModule, RouterModule, MatProgressBarModule],
  styleUrls: ['./tenants.component.css']
})
export class TenantsComponent implements AfterViewInit, OnInit {
  tenants$!: Observable<Array<Tenant>|any> 
  isloading:boolean = true
  TENANT_DATA: Tenant[] = [];
  displayedColumns: string[] = ['tenantId','companyName', 'primaryContact', 'status'];
  dataSource = new MatTableDataSource(this.TENANT_DATA);
  public selectedRow:String = '1';
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService, private router: Router){
  }



  @ViewChild(MatSort) sort!: MatSort
  ngAfterViewInit(): void {
  this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.handleGetTenants();
  }


  setSelected(row: Tenant):void {
    this.selectedRow = row.tenantId;
    // console.log(this.selectedRow);
  }

  navigateToDetails(tenantId:String):void {
    this.router.navigate(['/tenant-detail'], { queryParams: { tenantId: tenantId } });
  }

  async handleGetTenants() {
      this.tenants$ = this.apiService.get_tenants();
      await this.tenants$.subscribe((data: any) => {
        data.forEach(async (tenant:any , index:number) => {
          // console.log(tenant)
          let newTenant:Tenant = {
            tenantId: tenant.Realm,
            companyName: tenant.Name ,
            primaryContact: 'Primart Contact ' + (index + 1),
            status: 'Status ' + (index + 1),
            };
          this.TENANT_DATA.push(newTenant);
      })
      this.dataSource.data = this.TENANT_DATA;
      this.isloading=false
  })
  }

}
