import { Component, ViewChild, AfterViewInit, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSortModule, MatSort } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { Router, RouterModule } from "@angular/router";
import { SKUService, Sku } from "@kelyn-technologies/dsio-angular-lib/dsio-api";

@Component({
  selector: "app-skus",
  standalone: true,
  templateUrl: "./skus.component.html",
  imports: [
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    RouterModule,
  ],
  styleUrls: ["./skus.component.css"],
})
export class SkusComponent implements AfterViewInit, OnInit {
  displayedColumns: string[] = ["name", "description", "enabled"];
  skus: Sku[] = [];
  dataSource = new MatTableDataSource([] as Sku[]);
  constructor(private skuService: SKUService, public router: Router) {}

  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.handleGetSKUs();
  }

  navigateToDetails(skuId: string) {
    this.router.navigate([`skus/sku-detail/${skuId}`]);
  }

  async handleGetSKUs() {
    this.skuService.getSkus().subscribe((result) => {
      this.skus = result.skus;
      this.dataSource.data = this.skus;
    });
  }
}
