import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { Observable } from "rxjs";
import { User } from "../users/users.component";
import { KeycloakService } from "keycloak-angular";

@Component({
  selector: "app-tenant-users",
  standalone: true,
  templateUrl: "./tenant-users.component.html",
  imports: [MatButtonModule],
  styleUrls: ["./tenant-users.component.css"],
})
export class TenantUsersComponent {
  users$!: Observable<Array<User> | any>;
  constructor() {}

  async handleGetTenantUsers() {}
}
