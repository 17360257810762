import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, NgModule, SkipSelf } from '@angular/core';
import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpContext, HttpParams } from '@angular/common/http';

/**
 * Custom HttpParameterCodec
 * Workaround for https://github.com/angular/angular/issues/18261
 */
class CustomHttpParameterCodec {
  encodeKey(k) {
    return encodeURIComponent(k);
  }
  encodeValue(v) {
    return encodeURIComponent(v);
  }
  decodeKey(k) {
    return decodeURIComponent(k);
  }
  decodeValue(v) {
    return decodeURIComponent(v);
  }
}
const BASE_PATH = new InjectionToken('basePath');
const COLLECTION_FORMATS = {
  'csv': ',',
  'tsv': '   ',
  'ssv': ' ',
  'pipes': '|'
};
class Configuration {
  constructor(configurationParameters = {}) {
    this.apiKeys = configurationParameters.apiKeys;
    this.username = configurationParameters.username;
    this.password = configurationParameters.password;
    this.accessToken = configurationParameters.accessToken;
    this.basePath = configurationParameters.basePath;
    this.withCredentials = configurationParameters.withCredentials;
    this.encoder = configurationParameters.encoder;
    if (configurationParameters.encodeParam) {
      this.encodeParam = configurationParameters.encodeParam;
    } else {
      this.encodeParam = param => this.defaultEncodeParam(param);
    }
    if (configurationParameters.credentials) {
      this.credentials = configurationParameters.credentials;
    } else {
      this.credentials = {};
    }
    // init default dsioTenantId credential
    if (!this.credentials['dsioTenantId']) {
      this.credentials['dsioTenantId'] = () => {
        if (this.apiKeys === null || this.apiKeys === undefined) {
          return undefined;
        } else {
          return this.apiKeys['dsioTenantId'] || this.apiKeys['X-DSIO-TENANT-ID'];
        }
      };
    }
  }
  /**
   * Select the correct content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   * @param contentTypes - the array of content types that are available for selection
   * @returns the selected content-type or <code>undefined</code> if no selection could be made.
   */
  selectHeaderContentType(contentTypes) {
    if (contentTypes.length === 0) {
      return undefined;
    }
    const type = contentTypes.find(x => this.isJsonMime(x));
    if (type === undefined) {
      return contentTypes[0];
    }
    return type;
  }
  /**
   * Select the correct accept content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct accept content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   * @param accepts - the array of content types that are available for selection.
   * @returns the selected content-type or <code>undefined</code> if no selection could be made.
   */
  selectHeaderAccept(accepts) {
    if (accepts.length === 0) {
      return undefined;
    }
    const type = accepts.find(x => this.isJsonMime(x));
    if (type === undefined) {
      return accepts[0];
    }
    return type;
  }
  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   * @return True if the given MIME is JSON, false otherwise.
   */
  isJsonMime(mime) {
    const jsonMime = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
  }
  lookupCredential(key) {
    const value = this.credentials[key];
    return typeof value === 'function' ? value() : value;
  }
  defaultEncodeParam(param) {
    // This implementation exists as fallback for missing configuration
    // and for backwards compatibility to older typescript-angular generator versions.
    // It only works for the 'simple' parameter style.
    // Date-handling only works for the 'date-time' format.
    // All other styles and Date-formats are probably handled incorrectly.
    //
    // But: if that's all you need (i.e.: the most common use-case): no need for customization!
    const value = param.dataFormat === 'date-time' && param.value instanceof Date ? param.value.toISOString() : param.value;
    return encodeURIComponent(String(value));
  }
}

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class EntitlementService {
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    this.basePath = 'https://api.dev.datastorio.cloud/v1';
    this.defaultHeaders = new HttpHeaders();
    this.configuration = new Configuration();
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  createEntitlementForTenant(tenantId, entitlement, observe = 'body', reportProgress = false, options) {
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling createEntitlementForTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entitlements/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: entitlement,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getEntitlement(entitlementId, observe = 'body', reportProgress = false, options) {
    if (entitlementId === null || entitlementId === undefined) {
      throw new Error('Required parameter entitlementId was null or undefined when calling getEntitlement.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entitlements/${this.configuration.encodeParam({
      name: "entitlementId",
      value: entitlementId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getEntitlementByTenant(entitlementId, tenantId, observe = 'body', reportProgress = false, options) {
    if (entitlementId === null || entitlementId === undefined) {
      throw new Error('Required parameter entitlementId was null or undefined when calling getEntitlementByTenant.');
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getEntitlementByTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entitlements/${this.configuration.encodeParam({
      name: "entitlementId",
      value: entitlementId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getEntitlements(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entitlements`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getEntitlementsByTenant(tenantId, observe = 'body', reportProgress = false, options) {
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getEntitlementsByTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entitlements/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getEntitlementsForAllTenant(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entitlements/tenants`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateEntitlementForTenant(entitlementId, tenantId, entitlement, observe = 'body', reportProgress = false, options) {
    if (entitlementId === null || entitlementId === undefined) {
      throw new Error('Required parameter entitlementId was null or undefined when calling updateEntitlementForTenant.');
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling updateEntitlementForTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entitlements/${this.configuration.encodeParam({
      name: "entitlementId",
      value: entitlementId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: entitlement,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static {
    this.ɵfac = function EntitlementService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EntitlementService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: EntitlementService,
      factory: EntitlementService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EntitlementService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class SKUService {
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    this.basePath = 'https://api.dev.datastorio.cloud/v1';
    this.defaultHeaders = new HttpHeaders();
    this.configuration = new Configuration();
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  createSku(sku, observe = 'body', reportProgress = false, options) {
    if (sku === null || sku === undefined) {
      throw new Error('Required parameter sku was null or undefined when calling createSku.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/skus`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: sku,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getSku(skuId, observe = 'body', reportProgress = false, options) {
    if (skuId === null || skuId === undefined) {
      throw new Error('Required parameter skuId was null or undefined when calling getSku.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/skus/${this.configuration.encodeParam({
      name: "skuId",
      value: skuId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getSkus(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/skus`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateSku(skuId, sku, observe = 'body', reportProgress = false, options) {
    if (skuId === null || skuId === undefined) {
      throw new Error('Required parameter skuId was null or undefined when calling updateSku.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/skus/${this.configuration.encodeParam({
      name: "skuId",
      value: skuId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: sku,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static {
    this.ɵfac = function SKUService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SKUService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SKUService,
      factory: SKUService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SKUService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class SolutionService {
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    this.basePath = 'https://api.dev.datastorio.cloud/v1';
    this.defaultHeaders = new HttpHeaders();
    this.configuration = new Configuration();
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  createSolution(solutionId, createSolutionRequest, observe = 'body', reportProgress = false, options) {
    if (solutionId === null || solutionId === undefined) {
      throw new Error('Required parameter solutionId was null or undefined when calling createSolution.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/solutions/${this.configuration.encodeParam({
      name: "solutionId",
      value: solutionId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: createSolutionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  createSolutionForTenant(solutionId, tenantId, createSolutionRequest, observe = 'body', reportProgress = false, options) {
    if (solutionId === null || solutionId === undefined) {
      throw new Error('Required parameter solutionId was null or undefined when calling createSolutionForTenant.');
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling createSolutionForTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/solutions/${this.configuration.encodeParam({
      name: "solutionId",
      value: solutionId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: createSolutionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getSolutionById(solutionId, observe = 'body', reportProgress = false, options) {
    if (solutionId === null || solutionId === undefined) {
      throw new Error('Required parameter solutionId was null or undefined when calling getSolutionById.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/solutions/${this.configuration.encodeParam({
      name: "solutionId",
      value: solutionId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getSolutionByTenant(solutionId, tenantId, observe = 'body', reportProgress = false, options) {
    if (solutionId === null || solutionId === undefined) {
      throw new Error('Required parameter solutionId was null or undefined when calling getSolutionByTenant.');
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getSolutionByTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/solutions/${this.configuration.encodeParam({
      name: "solutionId",
      value: solutionId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getSolutions(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/solutions`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getSolutionsByTenant(tenantId, observe = 'body', reportProgress = false, options) {
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getSolutionsByTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/solutions/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateSolution(solutionId, updateSolutionRequest, observe = 'body', reportProgress = false, options) {
    if (solutionId === null || solutionId === undefined) {
      throw new Error('Required parameter solutionId was null or undefined when calling updateSolution.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/solutions/${this.configuration.encodeParam({
      name: "solutionId",
      value: solutionId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateSolutionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateSolutionForTenant(solutionId, tenantId, updateSolutionForTenantRequest, observe = 'body', reportProgress = false, options) {
    if (solutionId === null || solutionId === undefined) {
      throw new Error('Required parameter solutionId was null or undefined when calling updateSolutionForTenant.');
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling updateSolutionForTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/solutions/${this.configuration.encodeParam({
      name: "solutionId",
      value: solutionId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateSolutionForTenantRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static {
    this.ɵfac = function SolutionService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SolutionService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SolutionService,
      factory: SolutionService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SolutionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class TenantService {
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    this.basePath = 'https://api.dev.datastorio.cloud/v1';
    this.defaultHeaders = new HttpHeaders();
    this.configuration = new Configuration();
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getTenant(tenantId, observe = 'body', reportProgress = false, options) {
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getTenants(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/tenants`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateTenant(tenantId, tenant, observe = 'body', reportProgress = false, options) {
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling updateTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: tenant,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static {
    this.ɵfac = function TenantService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TenantService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TenantService,
      factory: TenantService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TenantService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class UsageService {
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    this.basePath = 'https://api.dev.datastorio.cloud/v1';
    this.defaultHeaders = new HttpHeaders();
    this.configuration = new Configuration();
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getAllUsage(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/usage/tenants`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getSolutionUsage(solutionId, year, month, day, hour, dimension, queryChildren, observe = 'body', reportProgress = false, options) {
    if (solutionId === null || solutionId === undefined) {
      throw new Error('Required parameter solutionId was null or undefined when calling getSolutionUsage.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (month !== undefined && month !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, month, 'month');
    }
    if (day !== undefined && day !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, day, 'day');
    }
    if (hour !== undefined && hour !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, hour, 'hour');
    }
    if (dimension !== undefined && dimension !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, dimension, 'dimension');
    }
    if (queryChildren !== undefined && queryChildren !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, queryChildren, 'query_children');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/usage/${this.configuration.encodeParam({
      name: "solutionId",
      value: solutionId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getSolutionUsageByTenant(solutionId, tenantId, year, month, day, hour, dimension, queryChildren, observe = 'body', reportProgress = false, options) {
    if (solutionId === null || solutionId === undefined) {
      throw new Error('Required parameter solutionId was null or undefined when calling getSolutionUsageByTenant.');
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getSolutionUsageByTenant.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (month !== undefined && month !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, month, 'month');
    }
    if (day !== undefined && day !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, day, 'day');
    }
    if (hour !== undefined && hour !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, hour, 'hour');
    }
    if (dimension !== undefined && dimension !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, dimension, 'dimension');
    }
    if (queryChildren !== undefined && queryChildren !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, queryChildren, 'query_children');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/usage/${this.configuration.encodeParam({
      name: "solutionId",
      value: solutionId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getTop3Usage(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/usage/top3`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getTop3UsageByTenant(tenantId, observe = 'body', reportProgress = false, options) {
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getTop3UsageByTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/usage/top3/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getUsage(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/usage`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getUsageByTenant(tenantId, observe = 'body', reportProgress = false, options) {
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getUsageByTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/usage/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getYTDUsage(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/usage/ytd`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getYTDUsageForTenant(tenantId, observe = 'body', reportProgress = false, options) {
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getYTDUsageForTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/usage/ytd/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static {
    this.ɵfac = function UsageService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UsageService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UsageService,
      factory: UsageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UsageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class UserService {
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    this.basePath = 'https://api.dev.datastorio.cloud/v1';
    this.defaultHeaders = new HttpHeaders();
    this.configuration = new Configuration();
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  createUser(user, observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/users`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: user,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  createUserForTenant(tenantId, user, observe = 'body', reportProgress = false, options) {
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling createUserForTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/users/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: user,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getUser(userId, observe = 'body', reportProgress = false, options) {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling getUser.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/users/${this.configuration.encodeParam({
      name: "userId",
      value: userId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getUserByTenant(userId, tenantId, observe = 'body', reportProgress = false, options) {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling getUserByTenant.');
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getUserByTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/users/${this.configuration.encodeParam({
      name: "userId",
      value: userId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getUserRoles(userId, observe = 'body', reportProgress = false, options) {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling getUserRoles.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/users/${this.configuration.encodeParam({
      name: "userId",
      value: userId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}/roles`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getUserRolesByTenant(userId, tenantId, observe = 'body', reportProgress = false, options) {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling getUserRolesByTenant.');
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getUserRolesByTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/users/${this.configuration.encodeParam({
      name: "userId",
      value: userId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}/roles/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getUsers(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/users`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getUsersByTenant(tenantId, observe = 'body', reportProgress = false, options) {
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getUsersByTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/users/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateUser(userId, user, observe = 'body', reportProgress = false, options) {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling updateUser.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/users/${this.configuration.encodeParam({
      name: "userId",
      value: userId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: user,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateUserByTenant(userId, tenantId, user, observe = 'body', reportProgress = false, options) {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling updateUserByTenant.');
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling updateUserByTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/users/${this.configuration.encodeParam({
      name: "userId",
      value: userId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: user,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateUserRoles(userId, userRole, observe = 'body', reportProgress = false, options) {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling updateUserRoles.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/users/${this.configuration.encodeParam({
      name: "userId",
      value: userId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}/roles`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: userRole,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateUserRolesForTenant(userId, tenantId, userRole, observe = 'body', reportProgress = false, options) {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling updateUserRolesForTenant.');
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling updateUserRolesForTenant.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (dsioTenantId) required
    localVarCredential = this.configuration.lookupCredential('dsioTenantId');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('X-DSIO-TENANT-ID', localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/users/${this.configuration.encodeParam({
      name: "userId",
      value: userId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}/roles/tenants/${this.configuration.encodeParam({
      name: "tenantId",
      value: tenantId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: userRole,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static {
    this.ɵfac = function UserService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserService,
      factory: UserService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
const APIS = [EntitlementService, SKUService, SolutionService, TenantService, UsageService, UserService];

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateSolutionForTenantRequest;
(function (UpdateSolutionForTenantRequest) {
  UpdateSolutionForTenantRequest.ActionEnum = {
    Configure: 'configure',
    Deconfigure: 'deconfigure',
    Enable: 'enable',
    Disable: 'disable'
  };
})(UpdateSolutionForTenantRequest || (UpdateSolutionForTenantRequest = {}));

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateSolutionRequest;
(function (UpdateSolutionRequest) {
  UpdateSolutionRequest.ActionEnum = {
    Configure: 'configure',
    Deconfigure: 'deconfigure',
    Enable: 'enable',
    Disable: 'disable'
  };
})(UpdateSolutionRequest || (UpdateSolutionRequest = {}));

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Datastorio API - OpenAPI 3.0
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

class ApiModule {
  static forRoot(configurationFactory) {
    return {
      ngModule: ApiModule,
      providers: [{
        provide: Configuration,
        useFactory: configurationFactory
      }]
    };
  }
  constructor(parentModule, http) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
  static {
    this.ɵfac = function ApiModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ApiModule)(i0.ɵɵinject(ApiModule, 12), i0.ɵɵinject(i1.HttpClient, 8));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ApiModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [],
      exports: [],
      providers: []
    }]
  }], () => [{
    type: ApiModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: i1.HttpClient,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { APIS, ApiModule, BASE_PATH, COLLECTION_FORMATS, Configuration, EntitlementService, SKUService, SolutionService, TenantService, UpdateSolutionForTenantRequest, UpdateSolutionRequest, UsageService, UserService };
