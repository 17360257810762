import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TenantDetailComponent } from "./tenant-detail/tenant-detail.component";
import { TenantSolutionsComponent } from "./tenant-solutions/tenant-solutions.component";
import { TenantEntitlementsComponent } from "./tenant-entitlements/tenant-entitlements.component";
import { TenantUsersComponent } from "./tenant-users/tenant-users.component";
import { UsersComponent } from "./users/users.component";
import { SkusComponent } from "./skus/skus.component";
import { TenantsComponent } from "./tenants/tenants.component";
import { SkusDetailComponent } from "./skus/sku-detail/skus-detail.component";
import { SolutionsWizardComponent } from "./tenant-solutions/solutions-wizard/solutions-wizard.component";

const routes: Routes = [
  { path: "", redirectTo: "/tenants", pathMatch: "full" },
  { path: "tenants", component: TenantsComponent },
  { path: "tenant-detail", component: TenantDetailComponent },
  { path: "tenant-solutions", component: TenantSolutionsComponent },
  { path: "tenant-entitlements", component: TenantEntitlementsComponent },
  { path: "tenant-users", component: TenantUsersComponent },
  { path: "users", component: UsersComponent },
  { path: "skus", component: SkusComponent },
  { path: "skus/sku-detail/:skuId", component: SkusDetailComponent },
  {
    path: "tenant-solutions/solution-wizard/:solutionId",
    component: SolutionsWizardComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
