import { NgModule, APP_INITIALIZER } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {provideAnimations} from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TenantDetailComponent } from './tenant-detail/tenant-detail.component';
import { TenantResourcesComponent } from './tenant-resources/tenant-resources.component';
import { TenantBillingComponent } from './tenant-billing/tenant-billing.component';
import { TenantSolutionsComponent } from './tenant-solutions/tenant-solutions.component';
import { TenantUsageComponent } from './tenant-usage/tenant-usage.component';
import { TenantEntitlementsComponent } from './tenant-entitlements/tenant-entitlements.component';
import { TenantUsersComponent } from './tenant-users/tenant-users.component';
import { UsersComponent } from './users/users.component';
import { ResourcesComponent } from './resources/resources.component';
import { SkusComponent } from './skus/skus.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { TenantsComponent } from './tenants/tenants.component';
import { UsageCardComponent } from './usage-card/usage-card.component';
import { NgChartsModule } from 'ng2-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { TenantsUsingResourceComponent } from './tenants-using-resource/tenants-using-resource.component';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {DsioUiLibraryModule} from "dsio-ui-library";
import { SkusDetailComponent } from './skus/sku-detail/skus-detail.component';




function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        realm: 'internal_admin_realm',
        url: `${environment.KEYCLOAK_URL}`, 
        clientId: 'websiteclient',
  
      },
      initOptions: {
        onLoad: 'login-required',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: ['/assets'],
      shouldUpdateToken: (request) => {
        return !(request.headers.get('token-update') === 'false');
      }
    });
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    TenantsUsingResourceComponent,
    DsioUiLibraryModule,
    UsageCardComponent,
    SkusDetailComponent,
    TenantsComponent,
    TenantBillingComponent,
    TenantUsageComponent,
    TenantUsersComponent,
    TenantEntitlementsComponent,
    SkusComponent,
    TenantDetailComponent,
    TenantResourcesComponent,
    ResourcesComponent,
    TenantSolutionsComponent,
    UsersComponent,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    KeycloakAngularModule,
    NgChartsModule,
    MatDialogModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "transparent",
      animationDuration: 300,
      showBackground: true,
      backgroundStrokeWidth: 16,
      backgroundStroke: "#F2F2F2",
      backgroundPadding: -8,

    })
  ],
  providers: [provideHttpClient(withInterceptorsFromDi()),{
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [KeycloakService]
  },provideAnimations(),
  {
    provide: MatDialogRef,
    useValue: {}
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
