import { Component, OnInit, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  ReactiveFormsModule,
  AbstractControl,
} from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import {
  SKUService,
  Sku,
  SolutionService,
} from "@kelyn-technologies/dsio-angular-lib/dsio-api";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";

@Component({
  selector: "skus-detail",
  standalone: true,
  templateUrl: "./skus-detail.component.html",
  imports: [
    MatSelectModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
  ],
  styleUrls: ["./skus-detail.component.css"],
})
export class SkusDetailComponent implements OnInit {
  @Input() skuId = "";
  sku = {} as Sku;
  skuFormGroup: FormGroup;
  solutions: string[] = [];
  loading = false;
  constructor(
    private skuService: SKUService,
    private _formBuilder: FormBuilder,
    private solutionService: SolutionService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.skuFormGroup = this._formBuilder.group({
      name: new FormControl("", [Validators.required]),
      description: new FormControl("", [Validators.required]),
      enabled: new FormControl(false, [Validators.required]),
      solutions: new FormControl([], [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.skuService.getSku(this.skuId).subscribe((result) => {
      this.sku = result.sku;
      this.skuFormGroup.setValue({
        name: this.sku.sku_name,
        description: this.sku.description,
        enabled: this.sku.enabled,
        solutions: this.sku.solutions,
      });
      this.skuFormGroup.get("name")?.disable();
      console.log(this.skuFormGroup.getRawValue());
    });
    this.solutionService.getSolutions().subscribe((result) => {
      this.solutions = result.solutions.map((solution) => solution.solution_id);
    });
  }

  saveClicked() {
    this.loading = true;
    this.sku.description = this.skuFormGroup.get("description")?.value;
    this.sku.enabled = this.skuFormGroup.get("enabled")?.value;
    this.sku.solutions = this.skuFormGroup.get("solutions")?.value;
    this.skuService.updateSku(this.sku.sku_name, this.sku).subscribe({
      next: (result) => {
        this.snackBar.open("Sku Saved");
        this.router.navigate(["/skus"]);
      },
      error: (result) => {
        this.snackBar.open(result.message, "Error");
        this.loading = false;
      },
    });
  }

  errorMessage(item: AbstractControl | null): string {
    if (!item) {
      return "";
    }
    if (item.hasError("required")) {
      return "This field is required";
    } else if (item.hasError("minlength")) {
      return (
        "This field must be at least " +
        item.errors?.["minlength"].requiredLength +
        " characters"
      );
    } else if (item.hasError("maxlength")) {
      return (
        "This field must be at most " +
        item.errors?.["maxlength"].requiredLength +
        " characters"
      );
    }
    return "";
  }
}
