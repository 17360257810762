<h2 mat-dialog-title>Select Entitlements</h2>

<mat-dialog-content class="dialog-content">
  <div *ngFor="let entitlement of allEntitlements">
    <mat-checkbox
      (change)="toggleSelection(entitlement)"
      [checked]="selectedEntitlements.includes(entitlement)"
      [disabled]="alreadyOwned(entitlement)"
    >
      {{ entitlement.product_source + '#' + entitlement.product_code }}
    </mat-checkbox>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="onSave()">Save</button>
</mat-dialog-actions>
