<dsio-tab-list class="tab-list">
  <dsio-tab tabTitle="Solutions">
    <app-tenant-solutions [tenantId]="tenantId" />
  </dsio-tab>
  <!-- <dsio-tab tabTitle="Usage"> 
    <app-tenant-usage />
  </dsio-tab>
  <dsio-tab tabTitle="Billing">
    <app-tenant-billing />
  </dsio-tab> -->
  <dsio-tab tabTitle="Entitlements">
    <app-tenant-entitlements [tenantId]="tenantId" />
  </dsio-tab>
</dsio-tab-list>
