import { Component, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatTabsModule } from "@angular/material/tabs";
import { ActivatedRoute, RouterModule } from "@angular/router";
import {
  TabListComponent,
  TabComponent,
} from "@kelyn-technologies/dsio-angular-lib";

import { TenantEntitlementsComponent } from "../tenant-entitlements/tenant-entitlements.component";
import { TenantSolutionsComponent } from "../tenant-solutions/tenant-solutions.component";

export interface Tenant {
  tenantId: string;
  companyName: string;
  primaryContact: string;
  status: string;
  action: any;
}

@Component({
  selector: "app-tenant-detail",
  standalone: true,
  templateUrl: "./tenant-detail.component.html",
  imports: [
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    TenantSolutionsComponent,
    TenantEntitlementsComponent,
    RouterModule,
    TabListComponent,
    TabComponent,
  ],
  styleUrls: ["./tenant-detail.component.css"],
})
export class TenantDetailComponent {
  @Input() public tenantId = "";

  constructor(public route: ActivatedRoute) {}
}
