import { CommonModule } from "@angular/common";
import {
  Component,
  ViewChild,
  AfterViewInit,
  OnInit,
  Input,
} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MatButtonModule } from "@angular/material/button";
import { MatSortModule, MatSort } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  EntitlementService,
  Entitlement,
} from "@kelyn-technologies/dsio-angular-lib/dsio-api";
import { AddEntitlementModalComponent } from './add-entitlement-modal/add-entitlement-modal.component';

@Component({
  selector: "app-tenant-entitlements",
  standalone: true,
  templateUrl: "./tenant-entitlements.component.html",
  imports: [MatButtonModule, MatTableModule, MatSortModule, CommonModule, MatDialogModule],
  styleUrls: ["./tenant-entitlements.component.css"],
})
export class TenantEntitlementsComponent implements AfterViewInit, OnInit {
  @Input() public tenantId = "";
  displayedColumns: string[] = [
    "Entitlement Name",
    "Type",
    "Start Date",
    "End Date",
  ];
  dataSource = new MatTableDataSource<Entitlement>([]);
  public selectedRow: string = "1";
  entitlements: Entitlement[] = [];

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private entitlementService: EntitlementService,
    private dialog: MatDialog,
    private http: HttpClient,
    private _snackbar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.handleGetTenantEntitlements();
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  setSelected(row: Entitlement): void {
    this.selectedRow = row.product_source + "#" + row.product_code;
  }

  addEntitlement(): void {
    // "datastorio_template" is a reserved tenant ID that holds template records
    this.entitlementService.getEntitlementsByTenant('datastorio_template')
      .subscribe((result) => {
        let allTemplateEntitlements = result.entitlements || [];

        // Filter out "marketplace" templates because those are managed
        // via the marketplace store and need to go through that workflow to
        // prevent drift between AWS Marketplace and DSIO
        allTemplateEntitlements = allTemplateEntitlements.filter(
          (e) => e.product_source !== 'aws_marketplace'
        );

        // Deselect the outer component to prevent accessibility issues
        const buttonElement = document.activeElement as HTMLElement;
        buttonElement.blur();

        const dialogRef = this.dialog.open(AddEntitlementModalComponent, {
          data: {
            allEntitlements: allTemplateEntitlements,
            userEntitlements: this.entitlements
          },
          width: '500px'
        });

        // Handle the dialog result
        dialogRef.afterClosed().subscribe((selectedEntitlements: Entitlement[]) => {
          if (selectedEntitlements && selectedEntitlements.length) {
            // Filter out duplicates that the user already has
            const newEntitlements = selectedEntitlements.filter(selected =>
              !this.entitlements.some(
                existing =>
                  existing.product_code === selected.product_code &&
                  existing.product_source === selected.product_source
              )
            );

            console.log('New entitlements only:', newEntitlements);

            const snack = this._snackbar
            newEntitlements.forEach((entitlement: Entitlement) => {
              entitlement.tenant_id = this.tenantId
              entitlement.tenant_name = this.tenantId
              console.log(entitlement)

              this.entitlementService.createEntitlementForTenant(this.tenantId, entitlement).subscribe({
                next(response) {
                  console.log(response);
                  snack.open("Entitlement Subscription successful", "OK")
                },
                error(err) {
                  console.log(err)
                  snack.open("Entitlement Subscription failed: " + err.error.reason, "FAIL")
                },
              })
            });
          }
        });
      });
  }

  removeEntitlement(_skuId: string): void {
    // console.log(` Remove Sku: ${skuId}`);
  }

  async handleGetTenantEntitlements() {
    this.entitlementService
      .getEntitlementsByTenant(this.tenantId)
      .subscribe((result) => {
        this.entitlements = result.entitlements;
        this.dataSource.data = this.entitlements;
      });
  }
}
