import { Component, ViewChild, AfterViewInit, OnInit } from "@angular/core";
import { RouterModule, Router } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatSortModule, MatSort } from "@angular/material/sort";
import { CommonModule } from "@angular/common";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {
  TenantService,
  Tenant,
} from "@kelyn-technologies/dsio-angular-lib/dsio-api";

@Component({
  selector: "app-tenants",
  standalone: true,
  templateUrl: "./tenants.component.html",
  imports: [
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    CommonModule,
    RouterModule,
    MatProgressBarModule,
  ],
  styleUrls: ["./tenants.component.css"],
})
export class TenantsComponent implements AfterViewInit, OnInit {
  isloading: boolean = true;
  displayedColumns: string[] = ["tenantId", "companyName"];
  tenants: Tenant[] = [];
  dataSource = new MatTableDataSource<Tenant>(this.tenants);
  public selectedRow: String = "1";
  constructor(private tenantService: TenantService, private router: Router) {}

  @ViewChild(MatSort) sort!: MatSort;
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.handleGetTenants();
  }

  setSelected(row: Tenant): void {
    this.selectedRow = row.tenant_name;
    // console.log(this.selectedRow);
  }

  navigateToDetails(tenantId: String): void {
    this.router.navigate(["/tenant-detail"], {
      queryParams: { tenantId: tenantId },
    });
  }

  async handleGetTenants() {
    this.tenantService.getTenants().subscribe((response) => {
      this.tenants = response.tenants;
      this.dataSource.data = this.tenants;
      this.isloading = false;
    });
  }
}
