<div class="inner-container">
  <mat-card class="card p-15 reg-card">
    <mat-card-title class="card-header">
      <h2 class="mt-0 mb-5">SKU Detail</h2>
    </mat-card-title>
    <mat-card-content class="card-content">
      <div class="">
        <mat-card-title>
          <h5 class="mt-0 mb-10">{{ sku.sku_name }}</h5>
        </mat-card-title>
        <form [formGroup]="skuFormGroup">
          <mat-form-field class="field input">
            <mat-label>Name</mat-label>
            <input matInput id="name" formControlName="name" name="name" value="" ngDefaultControl required />
            @if (skuFormGroup.get('name')?.invalid) {
              <mat-error style="margin-top: 8px">{{ errorMessage(skuFormGroup.get('name')) }}</mat-error>
            }
          </mat-form-field>
          <mat-form-field class="field input">
            <mat-label>Description</mat-label>
            <input matInput id="description" formControlName="description" name="description" required />
            @if (skuFormGroup.get('description')?.invalid) {
              <mat-error style="margin-top: 8px">{{ errorMessage(skuFormGroup.get('description')) }}</mat-error>
            }
          </mat-form-field>
          <mat-label>Enabled</mat-label>
          <mat-checkbox id="enabled" formControlName="enabled" name="enabled" required />
          <mat-form-field class="field input">
            <mat-label>Solutions</mat-label>
            <mat-select id="solutions" formControlName="solutions" name="solutions" required multiple>
              @for (solution of solutions; track solution) {
                <mat-option [value]="solution">{{ solution }}</mat-option>
              }
            </mat-select>
            @if (skuFormGroup.get('solutions')?.invalid) {
              <mat-error style="margin-top: 8px">{{ errorMessage(skuFormGroup.get('solutions')) }}</mat-error>
            }
          </mat-form-field>
          <div class="btn-group mb-15">
            <button
              mat-flat-button
              color="primary"
              class="secondary-btn"
              [disabled]="skuFormGroup.invalid || loading || skuFormGroup.disabled"
              (click)="saveClicked()"
            >
              @if (!loading) {
                <span>Save</span>
              } @else {
                <mat-spinner [diameter]="20"></mat-spinner>
              }
            </button>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>
