import {
  Component,
  ViewChild,
  AfterViewInit,
  OnInit,
  Inject,
  Input,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { Observable } from "rxjs";
import { KeycloakService } from "keycloak-angular";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSortModule, MatSort } from "@angular/material/sort";
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {
  MatSnackBar,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
  MatSnackBarModule,
  MatSnackBarRef,
} from "@angular/material/snack-bar";

export interface User {
  userId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  userName: string;
  role: string;
}

@Component({
  selector: "app-users",
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    CommonModule,
    MatProgressBarModule,
    MatSnackBarModule,
  ],
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements AfterViewInit, OnInit {
  users$!: Observable<Array<User> | any>;
  role$!: Observable<any>;

  newId$!: Observable<any>;
  USER_DATA: User[] = [];
  isloading: boolean = true;
  displayedColumns: string[] = ["userName", "emailAddress", "lastName", "role"];
  dataSource = new MatTableDataSource();

  editUserId: string = "";
  editUsername: string = "";
  editEmailAddress: string = "";
  editFirstName: string = "";
  editLastName: string = "";
  editRole: string = "";

  public selectedRow: string = "1";
  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar) {}
  @ViewChild(MatSort) sort!: MatSort;
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.handleGetUsers();
  }

  handleAddNewUser(): void {
    this.dialog.open(AddUserDialog, {
      width: "342px",
    });
  }

  setSelected(row: User): void {
    this.selectedRow = row.userId;
    this.editUserId = row.userId;
    this.editUsername = row.userName;
    this.editEmailAddress = row.emailAddress;
    this.editFirstName = row.firstName;
    this.editLastName = row.lastName;
    this.editRole = row.role;
    // console.log(this.selectedRow);
  }

  getSelected(userId: string): User {
    let user: User = {
      userId: "",
      emailAddress: "",
      userName: "",
      firstName: "",
      lastName: "",
      role: "",
    };
    this.USER_DATA.forEach((row: User) => {
      if (row.userId === userId) {
        user = row;
      }
    });
    return user;
  }

  handleAddNewUserDialog(): void {
    const dialogRef = this.dialog.open(AddUserDialog, {
      width: "342px",
      data: {
        userId: "",
        userName: "",
        emailAddress: "",
        firstName: "",
        lastName: "",
        role: "",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.handleAddUser(result);
    });
  }

  handleEditUserDialog(userId: string): void {
    const dialogRef = this.dialog.open(EditUserDialog, {
      width: "342px",
      data: {
        userId: this.editUserId,
        userName: this.editUsername,
        emailAddress: this.editEmailAddress,
        firstName: this.editFirstName,
        lastName: this.editLastName,
        role: this.editRole,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.handleEditUser(result);
      this.handleUpdateRole(result);
      this._snackBar.open("User Updated", "close");
      this.USER_DATA.forEach((row) => {
        if (row.userId === result.userId) {
          row.userName = result.userName;
          row.emailAddress = result.emailAddress;
          row.firstName = result.firstName;
          row.lastName = result.lastName;
          row.role = result.role;
        }
      });
    });
  }

  handleEditUser(userId: string): void {
    const user = this.getSelected(userId);
    // console.log({user})
    this.dialog.open(EditUserDialog, {
      width: "342px",
      data: {
        userId: user.userId,
        userName: user.userName,
        emailAddress: user.emailAddress,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
      },
    });
  }

  async getRole(userId: string, index: number) {}

  async handleUpdateRole(data: User): Promise<void> {}

  async handleGetUsers() {}

  async handleAddUser(user: any): Promise<void> {}
}

@Component({
  selector: "dsio-add-user-dialog",
  templateUrl: "./dsio-add-user-dialog.html",
  styleUrls: ["./users.component.css"],
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
})
export class AddUserDialog {
  constructor(public dialogRef: MatDialogRef<AddUserDialog>) {}
}

@Component({
  selector: "dsio-edit-user-dialog",
  templateUrl: "./dsio-edit-user-dialog.html",
  styleUrls: ["./users.component.css"],
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
})
export class EditUserDialog {
  constructor(
    public dialogRef: MatDialogRef<EditUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) {}

  consoleOutUser(data: any): void {
    // console.log(data)
  }
}
