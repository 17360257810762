
<mat-drawer-container class="content-container">
        <mat-drawer class="sidepanel" mode="side" opened>
                <ul class="nav-list">
                <img src="../assets/datastorioLogo.png" class="logo" />
        <li class="nav-item">
                <a class="link" routerLink="/tenants" routerLinkActive="active" ariaCurrentWhenActive="page"
                        #tenants="routerLinkActive">
                        <div class="menu-text">
                                <div *ngIf="tenants.isActive">
                                        <img src="../assets/tenant-icon-active.svg" />
                                </div>
                                <div *ngIf="!tenants.isActive">
                                        <img class="inactive" src="../assets/tenant-icon-inactive.svg" />
                                </div>
                                <p class="menu-name">Tenants</p>
                        </div>
                </a>
        </li>
        <!-- <li class="nav-item">
                <a class="link" routerLink="/solutions" routerLinkActive="active" ariaCurrentWhenActive="page"
                        #solutions="routerLinkActive">
                        <div class="menu-text">
                                <div *ngIf="solutions.isActive">
                                        <img src="../assets/icon-solutions.svg" />
                                </div>
                                <div *ngIf="!solutions.isActive">
                                        <img class="inactive" src="../assets/icon-solutions.svg" />
                                </div>
                                <p class="menu-name">Solutions</p>
                        </div>
                </a>
        </li> -->
        <!-- <li class="nav-item">
                <a class="link" routerLink="/users" routerLinkActive="active" ariaCurrentWhenActive="page"
                        #users="routerLinkActive">
                        <div class="menu-text">
                                <div *ngIf="users.isActive">
                                        <img src="../assets/users-active.svg" />
                                </div>
                                <div *ngIf="!users.isActive">
                                        <img class="inactive" src="../assets/users-inactive.svg" />
                                </div>
                                <p class="menu-name">Users</p>
                        </div>
                </a>
        </li> -->
        <!-- <li class="nav-item">
                <a class="link" routerLink="/resources" routerLinkActive="active" ariaCurrentWhenActive="page"
                        #resources="routerLinkActive">
                        <div class="menu-text">
                                <div *ngIf="resources.isActive">
                                        <img src="../assets/resources-active.svg" />
                                </div>
                                <div *ngIf="!resources.isActive">
                                        <img class="inactive" src="../assets/resources-inactive.svg" />
                                </div>
                                <p class="menu-name">Resources</p>
                        </div>
                </a>
        </li> -->
        <li class="nav-item">
                <a class="link" routerLink="/skus" routerLinkActive="active" ariaCurrentWhenActive="page"
                        #skus="routerLinkActive">
                        <div class="menu-text">
                                <div *ngIf="skus.isActive">
                                        <img src="../assets/skus-active.svg" />
                                </div>
                                <div *ngIf="!skus.isActive">
                                        <img class="inactive" src="../assets/skus-inactive.svg" />
                                </div>
                                <p class="menu-name">SKUs</p>
                        </div>
                </a>
        </li>
                <div>
                        <div class="nav-item logout">
                                <div class="link" (click)="logout()">
                                        <div class="menu-text">
                                                <img class="inactive" src="../assets/logout.png" />
                                                <p class="menu-name">Log Out</p>
                                        </div>
                                </div>
                        </div>
                </div>
        </ul>
        </mat-drawer>
        <mat-drawer-content>
                <div style="margin-left: 0px; display: flex; flex-direction: column;">
                        <!-- <div style="display: flex; flex-direction: row; justify-content: space-between;"> -->
                        <div class="header-container">
                                <div>
                                        <!-- <h1 style="margin-left: 0px; margin-top: 41px;">Good Afternoon {{firstName}}!</h1> -->
                                        <h1>Good Afternoon {{firstName}}!</h1>
                                        <!-- <p style="margin-left: 40px; margin-top: -15px;">dashboard/breadcrumb</p> -->
                                </div>
                                <div>
                                        <a [target]="'_blank'" [href]="environment.KEYCLOAK_URL + '/realms/internal_admin_realm/account/#/'">
                                                <img width="34" height="34" src="../assets/account_circle.png" />
                                        </a>
                                </div>
                        </div>
                        <router-outlet></router-outlet>
                </div>
        </mat-drawer-content>
        </mat-drawer-container>