import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Entitlement } from '@kelyn-technologies/dsio-angular-lib/dsio-api';

@Component({
  selector: 'app-add-entitlement-modal',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatCheckboxModule, MatDialogModule],
  templateUrl: './add-entitlement-modal.component.html'
})
export class AddEntitlementModalComponent {
  allEntitlements: Entitlement[] = [];
  selectedEntitlements: Entitlement[] = [];

  constructor(
    private dialogRef: MatDialogRef<AddEntitlementModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      allEntitlements: Entitlement[],
      userEntitlements: Entitlement[]
    }
  ) {}

  ngOnInit() {
    // Save all entitlements locally
    this.allEntitlements = this.data.allEntitlements;

    // Pre-select any entitlements that the user already has enabled
    for (const e of this.allEntitlements) {
      const userHasThisEntitlement = this.data.userEntitlements.some(
        u => u.product_source === e.product_source && u.product_code === e.product_code
      );
      if (userHasThisEntitlement) {
        this.selectedEntitlements.push(e);
      }
    }
  }

  toggleSelection(entitlement: Entitlement) {
    const i = this.selectedEntitlements.indexOf(entitlement);
    if (i >= 0) {
      this.selectedEntitlements.splice(i, 1);
    } else {
      this.selectedEntitlements.push(entitlement);
    }
  }

  alreadyOwned(entitlement: Entitlement): boolean {
    return this.data.userEntitlements.some(
      (u) =>
        u.product_source === entitlement.product_source &&
        u.product_code === entitlement.product_code
    );
  }

  onSave() {
    // Close the dialog and pass back the selected entitlements
    this.dialogRef.close(this.selectedEntitlements);
  }

  onCancel() {
    this.dialogRef.close();
  }
}
