import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Solution } from './tenant-solutions/tenant-solutions.component';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http:HttpClient) {}
  
  tenantId: string = 'internal_admin_realm'
  getUsers() {
    return this.http.get(`${environment.API_URL}/users?tenantId=${this.tenantId}`);
  }

  addUser(user: any, tenantId: string) {
  
    return this.http.post(`${environment.API_URL}/users?tenantId=${this.tenantId}`,
    {
      "username": user.userName,
      "enabled": true,
      "totp": false,
      "emailVerified": true,
      "firstName": user.firstName,
      "lastName": user.lastName,
      "email": user.emailAddress,
      "attributes": {
          "tenantId": [
              tenantId
          ]
      },
      "disableableCredentialTypes": [],
      "requiredActions": [],
      "notBefore": 0,
      "access": {
          "view": true
     
      }
  });
  }

  editUser(user: any) {


    return this.http.patch(`${environment.API_URL}/users/${user.userId}?tenantId=${this.tenantId}`,
    {
      email: user.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName
    });
  }

  getUserRole(userId: String) {
    return this.http.get(`${environment.API_URL}/users/${userId}/roles?tenantId=${this.tenantId}`);
  }

  updateUserRole(user:any) {
    return this.http.post(`${environment.API_URL}/users/${user.userId}/roles?tenantId=${this.tenantId}`,
    {"tenant_admin": user.isAdmin});
  }

  getSolutionsBySku(sku:string) {
    return this.http.post(`${environment.API_URL}/solutions/sku?tenantId=${this.tenantId}`,
    {"skuId": sku});
  }

  addOrRemoveSolution(input:string) {
    return this.http.patch(`${environment.API_URL}/solutions/sku?tenantId=${this.tenantId}`,
    input);
  }



  get_tenants() {
    return this.http.get(`${environment.API_URL}/tenants?tenantId=${this.tenantId}`);
  }
  //TODO: Add right url 
  get_tenant_resources(selectedTenant: String) {
    
    return this.http.get(`${environment.API_URL}/resources/admin/${selectedTenant}?tenantId=${this.tenantId}`);
  }
  //TODO: Add right url

  get_tenant_billing() {
    
    return this.http.get(`${environment.API_URL}/billing?tenantId=${this.tenantId}`);
  }
  //TODO: Add right url

  get_tenant_solutions(selectedTenant: string) {
   
      return this.http.get(`${environment.API_URL}/solutions/tenant/${selectedTenant}?tenantId=${this.tenantId}`);
    }
  //TODO: Add right url

  get_tenant_usage() {
    
    return this.http.get(`${environment.API_URL}/usage?tenantId=${this.tenantId}`);
  }
  //TODO: Add right url


  get_tenant_entitlements(selectedTenant: string) {
    
    return this.http.get(`${environment.API_URL}/entitlements/tenant/${selectedTenant}/?tenantId=${this.tenantId}`);
  }
  //TODO: Add right url

  get_tenant_users() {
    
    return this.http.get(`${environment.API_URL}/users?tenantId=${this.tenantId}`);
  }

  get_users() {
    
    return this.http.get(`${environment.API_URL}/users?tenantId=${this.tenantId}`);
  }
  //TODO: Add right url

  get_resources() {
    
    return this.http.get(`${environment.API_URL}/resources/admin/?tenantId=${this.tenantId}`);
  }
  //TODO: Add right url

  get_skus() {
    
    return this.http.get(`${environment.API_URL}/SKUs?tenantId=${this.tenantId}`);
  }

  get_skus_detail(skuId: string) { 
    return this.http.get(`${environment.API_URL}/SKUs/${skuId}?tenantId=${this.tenantId}`);
  }

  patch_tenant_solution_task(selectedTenant: string, solution: Solution) {
    return this.http.patch(`${environment.API_URL}/tenant_solutions/${solution.solutionId}/?tenantId=${selectedTenant}`, solution);
  }
}